import './portfolio.css'
import ProjectsCategories from './ProjectsCategories'
import data from './data'
import { useState } from 'react'
import React from "react"

const WorksProgreming = () => {
  const [projects, setProjects] = useState(data)
  const [activeProject, setActiveProject] = useState(null) // מעקב אחר כרטיס פעיל

  const categories = data.map(item => item.category)
  const uniqueCategories = ["all", ...new Set(categories)]

  const filterProjectsHandler = (category) => {
    if (category === "all") {
      setProjects(data)
      return
    }

    const filteredProjects = data.filter(project => project.category === category)
    setProjects(filteredProjects)
  }

  return (
    <section id="portfolio" dir="rtl"  >
      <h2 className="project-title ">סוגי פרוייקטים</h2>
      <p className="text-project">
        אלו דוגמאות לחלק מסוגי הפרוייקטים שנלמד לבנות:
        <br />
        <span className="highlight frontend">FrontEnd</span> - אתרי צד לקוח: אתרי תדמית לעסק.
        <br />
        <span className="highlight backend">BackEnd</span> - אתרים עם מסד נתונים, כמו NetFlix, Facebook, E-Commerce.
        <br />
        <span className="highlight apps">אפליקציות</span> - של תדמית לעסק ומכירות.
        <br />
        <span className="highlight games">משחקים</span> - עם מנועי משחקים Unity וגם ישירות עם קוד לדפדפן.
      </p>

      <div className="container portfolio__container">
        <ProjectsCategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler} />
        <div className="portfolio__projects">
          {projects.map((project, index) => (
            <div
              key={index}
              className={`portfolio__project ${activeProject === index ? 'active' : ''}`}
              onClick={() => setActiveProject(index)} // הגדרת כרטיס פעיל
            >
              <div className="portfolio__project-image">
                <img src={project.image} alt={project.title} />
              </div>
              <h3>{project.title}</h3>
              <p>{project.description}</p>
              <div className="portfolio__project-cta">
                <a href={project.github} className="btn" target="_blank" rel="noopener noreferrer">
                  GitHub
                </a>
                <a href={project.demo} className="btn btn-primary" target="_blank" rel="noopener noreferrer">
                  Demo
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default WorksProgreming
