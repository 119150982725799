import { useEffect } from 'react'
import faqs from './data'
import FAQ from './FAQ'
import AOS from 'aos'
import 'aos/dist/aos.css'
import './faqs.css'

const FAQs = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  })

  return (
    <section id="faqs" dir='rtl'>
      <div className="faqs__header">
      <h2 data-aos="zoom-in">שאלות נפוצות על פיתוח אתרים</h2>
<p data-aos="fade-up">
  כאן תמצאו תשובות לשאלות נפוצות על פיתוח אתרים. לחצו על השאלה כדי להציג את התשובה, ואם יש לכם שאלות נוספות – אל תהססו לפנות אליי דרך דף יצירת הקשר!
</p>

      </div>

      <div className="container faqs__container" data-aos="fade-in">
        {faqs && faqs.length > 0 ? (
          faqs.map(faq => <FAQ key={faq.id} faq={faq} />)
        ) : (
          <p>אין שאלות זמינות כרגע.</p>
        )}
      </div>
    </section>
  )
}

export default FAQs
