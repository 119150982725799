import React from "react";
import stevejobs from "../assets/stevejobs.png"; // ייבוא התמונה
import "./imagecomponent.css"

const ImageComponent = () => {
  return (
    <div className="image-container bg-[var(--bg-primary)]">
      <img src={stevejobs} alt="Steve Jobs" className="responsive-image" />
    </div>
  );
};

export default ImageComponent;
