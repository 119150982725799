import { motion } from "framer-motion";

const videos = [
  "/videos/hero-1.mp4",
  "/videos/hero-2.mp4",
  "/videos/hero-3.mp4",
  "/videos/hero-4.mp4",
];

const AnimatedSection = () => {
  return (
    <div className="relative w-screen h-screen flex flex-col items-center justify-center bg-black text-white overflow-hidden">
      {/* כותרת */}
      <motion.h1
        className="text-6xl font-bold z-10"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, ease: "easeOut" }}
      >
        WELCOME TO GAMING WORLD
      </motion.h1>

      {/* טקסט תיאור */}
      <motion.p
        className="text-lg text-gray-300 mt-4 z-10"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5, ease: "easeOut" }}
      >
        Discover a new dimension of interactive experiences with amazing effects.
      </motion.p>

      {/* ריבועי הווידאו בפינות */}
      {videos.map((video, index) => (
        <motion.div
          key={index}
    className="absolute bg-gray-800 rounded-lg overflow-hidden shadow-lg"
          initial={{
            opacity: 0,
            scale: 0.5,
            x: index % 2 === 0 ? "-100vw" : "100vw",
            y: index < 2 ? "-100vh" : "100vh",
          }}
          animate={{
            opacity: 1,
            scale: 1,
            x: 0,
            y: 0,
          }}
          transition={{
            duration: 2,
            ease: "easeOut",
            delay: index * 0.5,
          }}
          style={{
            width: "15vw", // 15% מרוחב המסך
            height: "15vh", // 15% מגובה המסך
            minWidth: "80px", // לא קטן מ-80px
            minHeight: "80px",
            maxWidth: "300px", // לא גדול מ-300px
            maxHeight: "300px",
            top: index < 2 ? "5%" : "auto",
            bottom: index >= 2 ? "5%" : "auto",
            left: index % 2 === 0 ? "5%" : "auto",
            right: index % 2 !== 0 ? "5%" : "auto",
          }}
        >
          <video
            src={video}
            autoPlay
            loop
            muted
            className="w-full h-full object-cover"
          />
        </motion.div>
      ))}
    </div>
  );
};

export default AnimatedSection;
