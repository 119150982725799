import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import { motion } from "framer-motion";

import "react-vertical-timeline-component/style.min.css";

import { styles } from "./styles";
import { experiences } from "./constants/index";
import { SectionWrapper } from "./hoc";
import { textVariant } from "./utils/motion";

const ExperienceCard = ({ experience }) => {
  return (
    <VerticalTimelineElement
      contentStyle={{
        background: "#1d1836",
        color: "#fff",
        direction: "rtl", // טקסט מימין לשמאל
        textAlign: "right", // יישור לימין
      }}
      contentArrowStyle={{ borderRight: "7px solid  #232631" }}
      date={experience.date}
      iconStyle={{
        background: experience.iconBg,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "4px", // מוסיף רווח למעלה ומוריד את האייקון טיפה למטה
      }}
      icon={
        <a href={experience.link} target="_blank" rel="noopener noreferrer">
        <div className='flex justify-center items-center w-full h-full'>
          <img
            src={experience.icon}
            alt={experience.company_name}
            className='w-[60%] h-[60%] object-contain'
          />
        </div>
        </a>
      }
    >
      <motion.div
        initial={{ opacity: 0, x: 100 }} // אנימציה התחלתית
        whileInView={{ opacity: 1, x: 0 }} // מצב סופי בעת צפייה
        transition={{ duration: 0.5 }} // משך האנימציה
        viewport={{ once: false, amount: 0.2 }} // הפעלת האנימציה בגלילה מחדש
      >
        <div>
          <h3 className='text-white text-[24px] font-bold'>{experience.title}</h3>
          <p
            className='text-secondary text-[16px] font-semibold'
            style={{ margin: 0 }}
          >
            {experience.company_name}
          </p>
        </div>

        <ul className='mt-5 list-disc ml-5 space-y-2'>
          {experience.points.map((point, index) => (
            <li
              key={`experience-point-${index}`}
              className='text-white-100 text-[14px] pl-1 tracking-wider'
            >
              {point}
              
            </li>
          ))}
        </ul>
      </motion.div>
    </VerticalTimelineElement>
  );
};

const Experience = () => {
  return (
    <>
      <motion.div variants={textVariant()}>
        <p
          className={`${styles.sectionSubText} text-center mt-20 flex flex-col sm:mt-20 pt-20 pt-25'`}
          style={{
            color: "var(--text-color)", // הגדרת צבע הטקסט מהמשתנה
          }}
        >
          תהליך הלמידה של הקורסים
        </p>
        <h2
          className={`${styles.sectionHeadText} text-center`}
          style={{
            color: "var(--text-color)", // הגדרת צבע הטקסט מהמשתנה
          }}
        >
          סדר שפות תכנות
        </h2>
      </motion.div>

      <div className='mt-20 flex flex-col sm:pt-10 pt-5'>
        <VerticalTimeline>
          {experiences.map((experience, index) => (
            <ExperienceCard
              key={`experience-${index}`}
              experience={experience}
            />
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
};

export default SectionWrapper(Experience, "work");
